import { z } from "zod";

export type ListCompany = z.infer<typeof listCompanySchema>;

const listCompanySchema = z.object({
  id: z.string(),
  organizationNumber: z.string(),
  name: z.string(),
  parentCompanyId: z.string().optional(),
});
