import { ChildCompany, UpdateChildCompany } from "@elton/types";

import { getServicesClient } from "@/api/clients/api-client.ts";

export const updateChildCompany = async (
  parentCompanyId: string,
  data: UpdateChildCompany,
): Promise<ChildCompany> => {
  const res = await getServicesClient().put(
    `/companies/${parentCompanyId}/companies/${data.id}`,
    {
      name: data.name,
      organizationNumber: data.organizationNumber,
      address: data.address,
      postalCode: data.postalCode,
      city: data.city,
      country: data.country,
      contactName: data.contactName,
      contactPhone: data.contactPhone,
      contactEmail: data.contactEmail,
    },
  );

  return res.data;
};
