import { UseMutationOptions } from "@tanstack/react-query";

import {
  ChildCompany,
  CreateChildCompany,
  UpdateChildCompany,
} from "@elton/types";

import { createChildCompany } from "@/api/requests/child-companies/create-child-company.ts";
import { deleteChildCompany } from "@/api/requests/child-companies/delete-child-company.ts";
import { updateChildCompany } from "@/api/requests/child-companies/update-child-company.ts";

export const createChildCompanyOptions = (
  parentCompanyId: string,
): UseMutationOptions<ChildCompany, Error, CreateChildCompany> => ({
  mutationFn: async (data: CreateChildCompany) =>
    createChildCompany(parentCompanyId, data),
});

export const updateChildCompanyOptions = (
  parentCompanyId: string,
): UseMutationOptions<ChildCompany, Error, UpdateChildCompany> => ({
  mutationFn: async (data: UpdateChildCompany) =>
    updateChildCompany(parentCompanyId, data),
});

export const deleteChildCompanyOptions = (parentCompanyId: string) => ({
  mutationFn: async (childCompanyId: string) =>
    deleteChildCompany(parentCompanyId, childCompanyId),
});
