import { createFileRoute } from "@tanstack/react-router";

import { BasePaymentPlan, List, ListCompany } from "@elton/types";

import { CompaniesPage } from "@/pages/companies-page/companies-page";
import { ErrorPage } from "@/pages/error-page/error-page";

import {
  DefaultListParamsSchema,
  getDefaultListLoaderDeps,
} from "@/api/params/default-list-params";
import { getCompanies } from "@/api/requests/companies/get-companies";
import { getBasePaymentPlans } from "@/api/requests/payment-plan/get-base-payment-plans";

export const Route = createFileRoute("/_authenticated/companies/")({
  component: CompaniesPage,
  errorComponent: ErrorPage,
  validateSearch: DefaultListParamsSchema,
  loaderDeps: getDefaultListLoaderDeps,
  loader: async ({
    deps,
  }): Promise<{
    companies: List<ListCompany>;
    paymentPlans: List<BasePaymentPlan>;
  }> => {
    return {
      companies: await getCompanies(deps),
      paymentPlans: await getBasePaymentPlans(),
    };
  },
});
