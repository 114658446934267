import { createFileRoute } from "@tanstack/react-router";

import { List, ListCompany } from "@elton/types";

import { ChildCompaniesPage } from "@/pages/child-companies-page/child-companies-page.tsx";
import { ErrorPage } from "@/pages/error-page/error-page.tsx";

import {
  DefaultListParamsSchema,
  getDefaultListLoaderDeps,
} from "@/api/params/default-list-params.ts";
import { getChildCompanies } from "@/api/requests/child-companies/get-child-companies.ts";

export const Route = createFileRoute(
  "/_authenticated/companies/$companyId/_dashboard/child-companies",
)({
  component: ChildCompaniesPage,
  errorComponent: ErrorPage,
  validateSearch: DefaultListParamsSchema,
  loaderDeps: getDefaultListLoaderDeps,
  loader: async ({ params }): Promise<List<ListCompany>> =>
    await getChildCompanies(params.companyId),
});
