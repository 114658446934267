import { ChildCompany, CreateChildCompany } from "@elton/types";

import { getServicesClient } from "@/api/clients/api-client.ts";

export const createChildCompany = async (
  parentCompanyId: string,
  data: CreateChildCompany,
): Promise<ChildCompany> => {
  const res = await getServicesClient().post<ChildCompany>(
    `/companies/${parentCompanyId}/companies`,
    {
      name: data.name,
      organizationNumber: data.organizationNumber,
      address: data.address,
      postalCode: data.postalCode,
      city: data.city,
      country: data.country,
      contactName: data.contactName,
      contactPhone: data.contactPhone,
      contactEmail: data.contactEmail,
    },
  );

  return res.data;
};
