import { getServicesClient } from "@/api/clients/api-client.ts";

export const deleteChildCompany = async (
  parentCompanyId: string,
  childCompanyId: string,
) => {
  const res = await getServicesClient().delete(
    `/companies/${parentCompanyId}/companies/${childCompanyId}/`,
  );

  return res.data;
};
