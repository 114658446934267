import { CreateEmployee } from "@elton/types";

import { getServicesClient } from "@/api/clients/api-client";

export const updateEmployee = async (data: CreateEmployee) => {
  const res = await getServicesClient().put(`/users/${data.id}`, {
    name: data.name,
    registrationNumber:
      data.registrationNumber === "" ? null : data.registrationNumber,
    defaultNote: data.defaultNote === "" ? null : data.defaultNote,
    reference: data.reference === "" ? null : data.reference,
    departmentId: data.departmentId,
  });

  return res.data;
};
