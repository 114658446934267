import { useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";

import { CreateChildCompany } from "@elton/types";

import { TFormAction } from "@/components/forms/form-types.ts";

interface IProps {
  data: CreateChildCompany;
  onSubmit: (value: CreateChildCompany, action: TFormAction) => void;
}

export const useCreateChildCompanyForm = ({ data, onSubmit }: IProps) => {
  const form = useForm({
    defaultValues: data,
    validatorAdapter: zodValidator(),
    onSubmit: (values) => {
      return onSubmit(values.value, "create");
    },
  });

  return form;
};
