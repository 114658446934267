import { useMutation } from "@tanstack/react-query";
import { useRouter } from "@tanstack/react-router";
import { AxiosError } from "axios";

import { useToast } from "@ui/components/ui/use-toast.tsx";

import { createChildCompanyOptions } from "@/api/options/child-company-options.ts";
import { useT } from "@transifex/react";

export const useCreateChildCompanyMutation = (parentCompanyId: string) => {
  const t = useT();
  const router = useRouter();
  const { toast } = useToast();

  return useMutation({
    ...createChildCompanyOptions(parentCompanyId),
    onSuccess: (data) => {
      toast({
        variant: "success",
        description: t("Branch company {name} was created successfully!", {
          name: data.name,
        }),
      });
      router.invalidate();
    },
    onError: (err: AxiosError<{ message?: string }>) => {
      toast({
        variant: "error",
        description: `${t("Branch company could not be created.")} ${err?.response?.data?.message ?? ""}`,
      });
    },
  });
};
