import { CreateCompany } from "@elton/types";
import { CreateChildCompany } from "../../types";

export const defaultCreateCompany: CreateCompany = {
  name: "",
  organizationNumber: "",
  address: "",
  postalCode: "",
  city: "",
  country: "Norge",
  contactName: "",
  contactPhone: "",
  invoiceEmail: "",
  discountPercentage: 0,
  contactEmail: "",
  invoicingReferences: undefined,
  canBeParent: false,
};

export const defaultCreateChildCompany: CreateChildCompany = {
  name: "",
  organizationNumber: "",
  address: "",
  postalCode: "",
  city: "",
  country: "Norge",
  contactName: "",
  contactPhone: "",
  contactEmail: "",
};
