import { CreateEmployee, Employee } from "../../types";

export const defaultCreateUser: CreateEmployee = {
  id: "",
  name: "",
  phoneNumber: "",
  reference: "",
  registrationNumber: "",
  defaultNote: "",
  departmentId: "",
};

export const defaultUsers: Employee[] = [
  {
    id: "1",
    name: "Jørgen Elton Nilsen",
    phoneNumber: "+4711111101",
    reference: "",
    registrationNumber: "EV111101",
    defaultNote: "",
    department: {
      name: "Operations",
      id: "1",
    },
  },
  {
    id: "2",
    name: "Nicolas Almagro Tonne",
    phoneNumber: "+4711111102",
    reference: "",
    registrationNumber: "EV111102",
    defaultNote: "",
    department: {
      name: "IT",
      id: "2",
    },
  },
  {
    id: "3",
    name: "May Helen Robertsen Storvik",
    phoneNumber: "+4711111103",
    reference: "",
    registrationNumber: "EV111103",
    defaultNote: "",
    department: {
      name: "IT",
      id: "2",
    },
  },
  {
    id: "4",
    name: "Karsten Gutterød Hauklien",
    phoneNumber: "+4711111104",
    reference: "",
    registrationNumber: "EV111104",
    defaultNote: "",
    department: {
      name: "Operations",
      id: "3",
    },
  },
  {
    id: "5",
    name: "Bård Kristian Haaland-Sørensen",
    phoneNumber: "+4711111105",
    reference: "",
    registrationNumber: "EV111105",
    defaultNote: "",
    department: {
      name: "IT",
      id: "2",
    },
  },
  {
    id: "6",
    name: "Charlotte Halle Roppen",
    phoneNumber: "+4711111106",
    reference: "",
    registrationNumber: "EV111106",
    defaultNote: "",
    department: {
      name: "Marketing",
      id: "4",
    },
  },
  {
    id: "7",
    name: "Espen Bang",
    phoneNumber: "+4711111107",
    reference: "",
    registrationNumber: "EV111107",
    defaultNote: "",
    department: {
      name: "IT",
      id: "2",
    },
  },
  {
    id: "8",
    name: "Tommy Nordli",
    phoneNumber: "+4711111108",
    reference: "",
    registrationNumber: "EV111108",
    defaultNote: "",
    department: {
      name: "IT",
      id: "2",
    },
  },
  {
    id: "9",
    name: "Øystein Nitter",
    phoneNumber: "+4711111109",
    reference: "",
    registrationNumber: "EV111109",
    defaultNote: "",
    department: {
      name: "Operations",
      id: "3",
    },
  },
  {
    id: "10",
    name: "Jørund Amsen",
    phoneNumber: "+4711111110",
    reference: "",
    registrationNumber: "EV111110",
    defaultNote: "",
    department: {
      name: "IT",
      id: "2",
    },
  },
  {
    id: "10",
    name: "Simone Bystadhagen",
    phoneNumber: "+4711111111",
    reference: "",
    registrationNumber: "EV111111",
    defaultNote: "",
    department: {
      name: "IT",
      id: "2",
    },
  },
];
