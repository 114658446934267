import { Building2, LayoutDashboard, PlugZap, Users } from "@elton/ui/icons";

import { useT } from "@transifex/react";
import { Fork } from "@ui/components/icons/Fork.tsx";

export const useSidebarNavigationLinks = (
  companyId: string,
  canBeParent: boolean,
) => {
  const t = useT();

  const navLinks = [
    {
      title: t("Dashboard"),
      href: `/companies/${companyId}/dashboard`,
      icon: LayoutDashboard,
      disabled: false,
    },
    {
      title: t("Company"),
      href: `/companies/${companyId}/company`,
      icon: Building2,
      disabled: false,
      subItems: [
        {
          title: t("Overview"),
          href: `/companies/${companyId}/company`,
          disabled: false,
        },
        {
          title: t("Departments"),
          href: `/companies/${companyId}/departments`,
          disabled: false,
        },
        {
          title: t("Admins"),
          href: `/companies/${companyId}/accounts`,
          disabled: false,
        },
        {
          title: t("Details"),
          href: `/companies/${companyId}/settings`,
          disabled: false,
        },
      ],
    },
    {
      title: t("Employees"),
      href: `/companies/${companyId}/employees`,
      icon: Users,
      disabled: false,
    },
    {
      title: t("Sessions"),
      href: `/companies/${companyId}/sessions`,
      icon: PlugZap,
      disabled: false,
    },
  ];

  if (canBeParent) {
    navLinks.push({
      title: t("Branch companies"),
      href: `/companies/${companyId}/child-companies`,
      icon: Fork,
      disabled: false,
    });
  }
  return navLinks;
};
