import { UpdateChildCompany, UpdateCompany } from "@elton/types";
import { formatCentsToDollars } from "@elton/utils";

import { ChildCompanyPartialForm } from "@/components/forms/child-company-form/partial/child-company-partial-form.tsx";
import { CompanyPartialForm } from "@/components/forms/company-form/partial/company-partial-form.tsx";

import { FormRenderState } from "@/pages/company-details-page/company-details-page.tsx";

import { useUpdateChildCompanyMutation } from "@/api/mutations/child-company/update-child-company-mutation.tsx";
import { useUpdateCompanyMutation } from "@/api/mutations/company/update-company-mutation.tsx";
import { useRole } from "@/context/role-context/use-role.tsx";
import { Route as CompanySettingsRoute } from "@/routes/_authenticated/companies/$companyId/_dashboard/settings.tsx";

type IProps = {
  setFormOpen: (open: boolean) => void;
  state: FormRenderState | null;
};

export const PartialForm: React.FC<IProps> = (props) => {
  const loaderData = CompanySettingsRoute.useLoaderData();
  const { isAdmin } = useRole();

  if (isAdmin) {
    return <CompanyForm {...props} />;
  } else if (loaderData.hasParentAccess && loaderData.company.parentCompanyId)
    return (
      <ChildCompanyForm
        parentCompanyId={loaderData.company.parentCompanyId}
        {...props}
      />
    );
};

const ChildCompanyForm: React.FC<IProps & { parentCompanyId: string }> = ({
  setFormOpen,
  state,
  parentCompanyId,
}) => {
  const loaderData = CompanySettingsRoute.useLoaderData();
  const updateChildCompany = useUpdateChildCompanyMutation(parentCompanyId);

  const handleUpdateChildCompany = async (data: UpdateChildCompany) => {
    await updateChildCompany.mutateAsync(data);
    setFormOpen(false);
  };

  return (
    <ChildCompanyPartialForm
      data={loaderData.company}
      onUpdate={handleUpdateChildCompany}
      loading={updateChildCompany.isPending}
      state={state}
    />
  );
};

const CompanyForm: React.FC<IProps> = ({ setFormOpen, state }) => {
  const loaderData = CompanySettingsRoute.useLoaderData();
  const updateCompany = useUpdateCompanyMutation();

  const handleUpdateCompany = async (data: UpdateCompany) => {
    await updateCompany.mutateAsync(data);
    setFormOpen(false);
  };

  return (
    <CompanyPartialForm
      data={{
        ...loaderData.company,
        paymentPlanId: loaderData.companyPlan?.basePlanId,
        userLimit: loaderData.companyPlan?.userLimit,
        monthlyCost: loaderData.companyPlan?.monthlyCost
          ? formatCentsToDollars(loaderData.companyPlan?.monthlyCost)
          : 0,
      }}
      paymentPlans={loaderData.paymentPlans.data}
      onUpdate={handleUpdateCompany}
      loading={updateCompany.isPending}
      state={state}
    />
  );
};
